import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"

import logo from "../../images/logo.svg"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">
          <div style={{ marginBottom: "20px" }}>
            <img className="img-fluid" src={logo} alt="Mission Mortgage Co" />
          </div>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/" style={{ color: "#000" }}>
          Home
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/about-us" style={{ color: "#000" }}>
          About
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/loan-process" style={{ color: "#000" }}>
          Loan Process
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/loan-programs" style={{ color: "#000" }}>
          Loan Programs
        </Link>
      </Nav.Item>
      <Nav.Item>
        <a
          href="https://plus.preapp1003.com/Darlene-Franklin/upload"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#000" }}
        >
          Upload Docs
        </a>
      </Nav.Item>
      <Nav.Item>
        <Link to="/blog" style={{ color: "#000" }}>
          Blog
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/contact" style={{ color: "#000" }}>
          Contact
        </Link>
      </Nav.Item>
    </Nav>
  </div>
)
