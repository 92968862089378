import React from "react"
import { Link } from "gatsby"

import logo from "../../images/logo.svg"
import eho from "../../images/eho-img.png"

const footer = props => {
	return (
		<div>
			<div className="container footer-section">
				<div className="row">
					<div className="col-md-6">
						<img src={logo} alt="Mission Mortgage Logo" />
						<p className="pb-4">
							Licensed to do business in the State of Colorado |<br />
							Mission Mortgage Colorado |<br />
							MLO 100019837 | NMLS &nbsp;272802 | Co NMLS 1813312
							<br />
						</p>
					</div>
					<div className="col-md-4"></div>
					<div className="col-md-2">
						<h5 className="mt-0">Quick Links</h5>
						<ul className="list-unstyled">
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<Link to="/about-us">About</Link>
							</li>
							<li>
								<Link to="/loan-process">Loan Process</Link>
							</li>
							<li>
								<Link to="/loan-programs">Loan Programs</Link>
							</li>
							<li>
								<a
									href="https://plus.preapp1003.com/Darlene-Franklin/upload"
									target="_blank"
									rel="noopener noreferrer"
								>
									Upload Docs
								</a>
							</li>
							<li>
								<Link to="/blog">Blog</Link>
							</li>
							<li>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
					</div>
					<div className="col-md-12 text-center">
						<img
							className="eho-img pb-0"
							src={eho}
							alt="Equal Housing Opportunity"
						/>
						<p className="mt-2">
							Mission Mortgage Colorado is an Equal Housing Opportunity
						</p>
					</div>
				</div>
			</div>

			{/* <div class="footer bg-dark text-white">
				<div class="container">
					<div class="row justify-content-between">
						<div class="col-md-5 mb-3 mb-md-0">
							<h6 class="text-uppercase text-white-50">About</h6>
							<p class="small">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat.
							</p>
						</div>
						<div class="col-md-2 mb-3 mb-md-0">
							<h6 class="text-uppercase text-white-50">Company</h6>
							<ul class="list-unstyled small">
								<li>
									<Link to="/">Home</Link>
								</li>
								<li>
									<Link to="/about-us">About Us</Link>
								</li>
								<li>
									<Link to="/blog">Blog</Link>
								</li>
								<li />
							</ul>
						</div>
						<div class="col-md-2 mb-3 mb-md-0">
							<h6 class="text-uppercase text-white-50">Services</h6>
							<ul class="list-unstyled small">
								<li>
									<Link to="/">Web Design</Link>
								</li>
								<li>
									<Link to="/">Content Creation</Link>
								</li>
								<li>
									<Link to="/">Development</Link>
								</li>
							</ul>
						</div>
						<div class="col-md-2 text-center mb-3 mb-md-0">
							<div class="social-icons">
								<a
									href="https://www.facebook.com/"
									target="_blank"
									rel="noreferrer noopener"
								>
									<i class="fa fa-facebook" />
								</a>
								<a
									href="https://twitter.com/"
									target="_blank"
									rel="noreferrer noopener"
								>
									<i class="fa fa-twitter" />
								</a>
								<a
									href="https://www.instagram.com/"
									target="_blank"
									rel="noreferrer noopener"
								>
									<i class="fa fa-instagram" />
								</a>
								<a
									href="https://www.linkedin.com/"
									target="_blank"
									rel="noreferrer noopener"
								>
									<i class="fa fa-linkedin" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="copyright">
				<div class="container">
					<span>
						© 2019 All rights reserved. Designed and powered by {``}
						<a
							href="https://webriq.com/"
							target="_blank"
							rel="noopener noreferrer"
						>
							WebriQ
						</a>
						.
					</span>
				</div>
			</div> */}
		</div>
	)
}

export default footer
