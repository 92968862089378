import React from "react"
import { Link } from "gatsby"
import SideBarMenu from "../sidebar/sidebar"
import Sticky from "react-sticky-el"
import styl from "./header.module.css"

import logo from "../../images/logo.svg"

// import { NavDropdown } from "react-bootstrap"

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "2" }}
    >
      <div className={`header ${styl.header}`}>
        <div className="container-fluid c-fluid-padding">
          <div className="row justify-content-between align-items-center">
            <div className="col-3">
              <div className="header-logo">
                <Link to="/">
                  <div className="nav-brand">
                    <img
                      className="img-fluid"
                      src={logo}
                      alt="Mission Mortgage Co"
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-9">
              <div className="header-nav-wrapper d-none-mobile float-right">
                <ul className="nav vertical-align">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About</Link>
                  </li>
                  <li>
                    <Link to="/loan-process">Loan Process</Link>
                  </li>
                  <li>
                    <Link to="/loan-programs">Loan Programs</Link>
                  </li>
                  <li>
                    <a
                      href="https://plus.preapp1003.com/Darlene-Franklin/upload"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Upload Docs
                    </a>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/#review-section-wrap">Reviews</Link>
                  </li>
                  <li>
                    <a
                      href="https://plus.preapp1003.com/Darlene-Franklin"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn-global mr-3"
                      type="button"
                    >
                      Apply Now
                    </a>
                  </li>
                </ul>
              </div>
              <div className="text-right d-none-desktop">
                <SideBarMenu />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
